var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5" },
        [
          _c(
            "c-table",
            {
              ref: "table",
              attrs: {
                title: "공지사항 목록",
                tableId: "table",
                columnSetting: false,
                isFullScreen: false,
                columns: _vm.grid.columns,
                data: _vm.grid.data,
                isExcelDown: false,
              },
              on: { rowClick: _vm.rowClick },
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _c("c-btn", {
                        attrs: { label: "검색", icon: "search" },
                        on: { btnClicked: _vm.getNoticeList },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7" },
        [
          _c(
            "q-form",
            { ref: "editForm" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "상세" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable
                            ? _c("c-btn", {
                                attrs: { label: "신규", icon: "add" },
                                on: { btnClicked: _vm.addNotice },
                              })
                            : _vm._e(),
                          _vm.editable && _vm.saveable
                            ? _c("c-btn", {
                                attrs: {
                                  url: _vm.saveUrl,
                                  isSubmit: _vm.isSave,
                                  param: _vm.data,
                                  mappingType: _vm.saveType,
                                  label: "저장",
                                  icon: "save",
                                },
                                on: {
                                  beforeAction: _vm.saveNotice,
                                  btnCallback: _vm.saveCallback,
                                },
                              })
                            : _vm._e(),
                          _vm.editable && _vm.deleteable
                            ? _c("c-btn", {
                                attrs: {
                                  url: _vm.deleteUrl,
                                  isSubmit: true,
                                  param: _vm.data,
                                  mappingType: "DELETE",
                                  label: "삭제",
                                  icon: "remove",
                                },
                                on: {
                                  beforeAction: _vm.deleteNotice,
                                  btnCallback: _vm.deleteCallback,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-8" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable && _vm.dataeditable,
                            required: true,
                            label: "공지사항 제목",
                            name: "noticeTitle",
                          },
                          model: {
                            value: _vm.data.noticeTitle,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "noticeTitle", $$v)
                            },
                            expression: "data.noticeTitle",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-select", {
                          attrs: {
                            editable: _vm.editable && _vm.dataeditable,
                            codeGroupCd: "NOTICE_CLASS_CD",
                            type: "edit",
                            required: true,
                            itemText: "codeName",
                            itemValue: "code",
                            name: "grade",
                            label: "공지사항분류",
                          },
                          model: {
                            value: _vm.data.noticeClassCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "noticeClassCd", $$v)
                            },
                            expression: "data.noticeClassCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.editable,
                            expression: "editable",
                          },
                        ],
                        staticClass: "col-4",
                      },
                      [
                        _c("c-radio", {
                          attrs: {
                            editable: _vm.editable && _vm.dataeditable,
                            comboItems: _vm.popupFlagItems,
                            label: "팝업여부",
                            name: "popupFlag",
                          },
                          model: {
                            value: _vm.data.popupFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "popupFlag", $$v)
                            },
                            expression: "data.popupFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.editable && _vm.data.popupFlag == "Y",
                            expression: "editable && data.popupFlag == 'Y'",
                          },
                        ],
                        staticClass: "col-4",
                      },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            editable: _vm.editable && _vm.dataeditable,
                            label: "팝업 시작일자",
                            required: _vm.data.popupFlag == "Y",
                            name: "popupStartDate",
                            end: _vm.data.popupEndDate,
                          },
                          model: {
                            value: _vm.data.popupStartDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "popupStartDate", $$v)
                            },
                            expression: "data.popupStartDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.editable && _vm.data.popupFlag == "Y",
                            expression: "editable && data.popupFlag == 'Y'",
                          },
                        ],
                        staticClass: "col-4",
                      },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            editable: _vm.editable && _vm.dataeditable,
                            label: "팝업 종료일자",
                            required: _vm.data.popupFlag == "Y",
                            name: "popupEndDate",
                            start: _vm.data.popupStartDate,
                          },
                          model: {
                            value: _vm.data.popupEndDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "popupEndDate", $$v)
                            },
                            expression: "data.popupEndDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable && _vm.dataeditable,
                            type: "editor",
                            label: "내용",
                            name: "noticeContents",
                          },
                          model: {
                            value: _vm.data.noticeContents,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "noticeContents", $$v)
                            },
                            expression: "data.noticeContents",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }